import CookieConsent from 'react-cookie-consent'
import React from 'react'
import { Link } from 'gatsby'
import './CookiesConsent.scss'

const CookiesContainer = () => {
    return (
        <CookieConsent
            buttonText="Accept cookies"
            buttonWrapperClasses="button-cookies"
            buttonStyle={{
                backgroundColor: 'transparent',
                color: '#fff',
                fontSize: 18,
                border: '2px solid #fff',
                marginRight: 100,
            }}
            expires={7}
            style={{ backgroundColor: '#4bbbe9' }}
            debug
        >
            <div className="cookies">
                <img className="cookies__img" src="/cookies.svg" alt="" />
                <div className="cookies__content">
                    <p>This website uses cookies, for more information read our Privacy Policy</p>
                    <Link className="cookies__link" to="/privacy-policy">
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </CookieConsent>
    )
}

export default CookiesContainer
