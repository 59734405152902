import React, { createContext, useState, useContext } from 'react'
import IScrollContext from '../interfaces/scroll-context.interface'
import IGenericProvider from '../interfaces/generic-provider.interface'

const ScrollContext = createContext<IScrollContext>(null as any)

export const ScrollProvider: React.FC<IGenericProvider> = ({ children }) => {
    const [scrollPosition, setScrollPosition] = useState(0)

    return (
        <ScrollContext.Provider value={{ scrollPosition, setScrollPosition }}>{children}</ScrollContext.Provider>
    )
}

export const useScrollContext = () => useContext(ScrollContext)
