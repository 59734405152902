exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-app-cost-calculator-tsx": () => import("./../../../src/pages/app-cost-calculator.tsx" /* webpackChunkName: "component---src-pages-app-cost-calculator-tsx" */),
  "component---src-pages-app-ideas-tsx": () => import("./../../../src/pages/app-ideas.tsx" /* webpackChunkName: "component---src-pages-app-ideas-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-odoo-tsx": () => import("./../../../src/pages/odoo.tsx" /* webpackChunkName: "component---src-pages-odoo-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-services-ai-automation-tsx": () => import("./../../../src/pages/services/ai-automation.tsx" /* webpackChunkName: "component---src-pages-services-ai-automation-tsx" */),
  "component---src-pages-services-android-app-development-tsx": () => import("./../../../src/pages/services/android-app-development.tsx" /* webpackChunkName: "component---src-pages-services-android-app-development-tsx" */),
  "component---src-pages-services-app-idea-validation-services-tsx": () => import("./../../../src/pages/services/app-idea-validation-services.tsx" /* webpackChunkName: "component---src-pages-services-app-idea-validation-services-tsx" */),
  "component---src-pages-services-cloud-services-tsx": () => import("./../../../src/pages/services/cloud-services.tsx" /* webpackChunkName: "component---src-pages-services-cloud-services-tsx" */),
  "component---src-pages-services-cto-tsx": () => import("./../../../src/pages/services/cto.tsx" /* webpackChunkName: "component---src-pages-services-cto-tsx" */),
  "component---src-pages-services-dev-ops-tsx": () => import("./../../../src/pages/services/dev-ops.tsx" /* webpackChunkName: "component---src-pages-services-dev-ops-tsx" */),
  "component---src-pages-services-digital-transformation-for-business-tsx": () => import("./../../../src/pages/services/digital-transformation-for-business.tsx" /* webpackChunkName: "component---src-pages-services-digital-transformation-for-business-tsx" */),
  "component---src-pages-services-full-stack-development-tsx": () => import("./../../../src/pages/services/full-stack-development.tsx" /* webpackChunkName: "component---src-pages-services-full-stack-development-tsx" */),
  "component---src-pages-services-gaming-solutions-tsx": () => import("./../../../src/pages/services/gaming-solutions.tsx" /* webpackChunkName: "component---src-pages-services-gaming-solutions-tsx" */),
  "component---src-pages-services-ios-development-tsx": () => import("./../../../src/pages/services/ios-development.tsx" /* webpackChunkName: "component---src-pages-services-ios-development-tsx" */),
  "component---src-pages-services-iot-app-development-tsx": () => import("./../../../src/pages/services/iot-app-development.tsx" /* webpackChunkName: "component---src-pages-services-iot-app-development-tsx" */),
  "component---src-pages-services-mobile-app-development-tsx": () => import("./../../../src/pages/services/mobile-app-development.tsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-tsx" */),
  "component---src-pages-services-react-native-app-development-tsx": () => import("./../../../src/pages/services/react-native-app-development.tsx" /* webpackChunkName: "component---src-pages-services-react-native-app-development-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-services-web-app-development-tsx": () => import("./../../../src/pages/services/web-app-development.tsx" /* webpackChunkName: "component---src-pages-services-web-app-development-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-templates-blog-page-template-tsx": () => import("./../../../src/templates/blogPageTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-page-template-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("./../../../src/templates/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */),
  "component---src-templates-project-page-template-tsx": () => import("./../../../src/templates/projectPageTemplate.tsx" /* webpackChunkName: "component---src-templates-project-page-template-tsx" */)
}

