import React, { useRef } from 'react'
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import { useSiteMetadata } from '../../customHooks/use-site-metadata'

interface IChatProps {
    children: React.ReactNode
}

const Chat = ({ children }: IChatProps) => {
    const tawkMessengerRef = useRef(null)
    const { tawkId } = useSiteMetadata()

    return (
        <>
            <TawkMessengerReact propertyId={tawkId} widgetId="default" ref={tawkMessengerRef} />
            {children}
        </>
    )
}

export default Chat
