import { graphql, useStaticQuery } from 'gatsby'

interface returnData {
    title: string
    description: string
    image: string
    siteUrl: string
    tawkId: string
}

export const useSiteMetadata = (): returnData => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    image
                    siteUrl
                    tawkId
                }
            }
        }
    `)

    return data.site.siteMetadata
}
