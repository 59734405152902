import './src/assets/styles/global.scss'
import 'react-responsive-modal/styles.css'
import Chat from './src/components/chat/Chat'
import React from 'react'
import { getCookieConsentValue } from 'react-cookie-consent'
import CookiesContainer from './src/components/common/CookiesConsent/CookiesConsent'
import { ScrollProvider } from './src/contexts/ScrollContext'
import awsconfig from './src/aws-exports'
import { Amplify } from 'aws-amplify'
Amplify.configure(awsconfig)

export const wrapRootElement = ({ element }) => {
    const isCookieAccepted = Boolean(getCookieConsentValue())
    return (
        <ScrollProvider>
            <>
                <Chat>{element}</Chat>
                {isCookieAccepted ? <CookiesContainer /> : null}
            </>
        </ScrollProvider>
    )
}
